import { Alert } from '../../components/dialog';
import React, { useState } from 'react';

const useProvideDialog = (setData) => {
  const showAlert = ({
    title,
    showIcon,
    icon,
    txtRight,
    txtLeft,
    onRight,
    onLeft,
  }) => {
    setData({
      show: true,
      showIcon: true,
      title,
      icon,
      txtRight,
      txtLeft,
      onRight,
      onLeft,
    });
  };

  const dismissAlert = () =>
    setData({
      show: false,
      title: undefined,
      icon: undefined,
      txtRight: undefined,
      txtLeft: undefined,
      onRight: undefined,
      onLeft: undefined,
    });

  const methods = {
    showAlert,
    dismissAlert,
  };

  window.alert = (title, confirm = () => {}, showIcon = true) => {
    showAlert({
      title,
      txtRight: '確定',
      showIcon,
      onLeft: () => {
        dismissAlert();
      },
      onRight: () => {
        dismissAlert();
        confirm();
      },
    });
  };

  window.confirm = (title, txtRight, txtLeft) => {
    return new Promise((resolve) => {
      showAlert({
        title,
        txtRight: txtRight ?? '確定',
        txtLeft: txtLeft ?? '取消',
        onLeft: () => {
          dismissAlert();
        },
        onRight: () => {
          dismissAlert();
          resolve();
        },
      });
    });
  };

  window.popup = (title) => {
    showAlert({
      title,
    });
    setTimeout(() => {
      dismissAlert();
    }, 1000);
  };

  window.Alert = (params = {}) => {
    showAlert({
      title: params.title,
      txtLeft: params.txtLeft,
      txtRight: params.txtRight ?? '確認',
      onRight: () => {
        dismissAlert();
        params.onRight && params.onRight();
      },
      onLeft: () => {
        dismissAlert();
        params.onLeft && params.onLeft();
      },
    });
  };

  return methods;
};

export default useProvideDialog;
