import React, { useState, useEffect } from 'react';

import Button from '../button';
import DialogBase from './_base';
import InputText from '../input-text';
import { useAPI } from 'utils/api';
import { decode } from 'js-base64';
import { API_RESPONSE } from 'api/_const';
import WebFn, { callAppFunc } from 'utils/jsbridge/index.js';
import { showLoading, dismissLoading } from 'utils/loading';

const EmailCombineDialog = ({
  show = false,
  onUpdateFinished = () => {},
  onClose = () => {},
}) => {
  const [email, setEmail] = useState('');
  const api = useAPI();
  const [memberProfile, setMemberProfile] = useState({
    mobile: '',
    name: '',
    birthday: '',
    twid: '',
    email: '',
    cityId: '',
    districtId: '',
    address: '',
    printflag: 0,
    detailflag: 0,
  });

  useEffect(() => {
    // callAppFunc('fetchMemberData', {});
  }, []);

  const validEmail = () => {
    // 正则表达式验证电子邮件地址的模式
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // 使用正则表达式验证电子邮件地址
    if (pattern.test(email)) {
      return true; // 有效的电子邮件地址
    }

    return false; // 无效的电子邮件地址
  };

  const emailCombineDialogCallback = (strJson) => {
    const dataJson = JSON.parse(strJson);
    console.log('EmailCombineDialog jsonData', dataJson);
    if (dataJson.fnName === 'sendMemberData') {
      const dataJsonString = decode(dataJson.params.data);
      const jsonData = JSON.parse(dataJsonString);
      setMemberProfile((state) => ({
        ...state,
        ...jsonData,
        cityId: jsonData.city,
        districtId: jsonData.district,
      }));
      setEmail(jsonData.email);
    }
  };

  const updateMemberProfile = () => {
    if (!validEmail()) {
      alert('請輸入正確的Email格式');
      return;
    }
    const param = {
      name: memberProfile.name,
      birthday: memberProfile.birthday,
      twid: memberProfile.twid,
      email: email,
      cityId: memberProfile.cityId,
      districtId: memberProfile.districtId,
      address: memberProfile.address,
      printflag: memberProfile.printflag,
      detailflag: memberProfile.detailflag,
    };
    console.log('updateMemberProfile', param);
    showLoading();
    api
      .updateMemberProfile(param)
      .then((res) => {
        dismissLoading();
        if (res.code !== API_RESPONSE.SUCCESS) {
          confirm(res.reason, '重試', '取消').then(() => {
            updateMemberProfile();
          });
          return;
        }

        onUpdateFinished();
      })
      .catch(() => {
        dismissLoading();
      });
  };

  useEffect(() => {
    if (show) {
      WebFn({ callback: emailCombineDialogCallback });
      callAppFunc('fetchMemberData', {});
    }
  }, [show]);

  return (
    <>
      <DialogBase show={show}>
        <header>
          請輸入Email以使用
          <br />
          折價券/折扣碼
        </header>

        <div className="password">
          <InputText
            placeholder="請輸入Email"
            type="text"
            text={email}
            onChangeValue={setEmail}
          ></InputText>
        </div>

        <footer>
          <Button
            onClick={() => {
              onClose();
            }}
            text="取消"
            gray
          />
          <Button
            onClick={() => {
              updateMemberProfile();
            }}
            text="送出"
          />
        </footer>
      </DialogBase>

      <style jsx>
        {`
          header {
            margin-bottom: 29px;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 29px;
            color: #3b3516;
          }
          .information {
            background: #f8f8f8;
            padding: 16px;
            margin-bottom: 32px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: min(10px, 5vw);
            align-items: center;

            .info-text {
              width: 136px;
              text-align: left;
            }
          }

          .password {
            position: relative;
            margin-bottom: 32px;

            .resend-button {
              position: absolute;
              top: 10px;
              right: 10px;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 23px;
              text-align: right;
              color: #5fd2da;
            }
          }

          footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 8px;
          }
        `}
      </style>
    </>
  );
};

export default EmailCombineDialog;
